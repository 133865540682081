<template>
  <div class="photos-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>图片管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-tabs v-model="activeClassify" tab-position="left" style="height: 100%;" @tab-click="changeClassify" v-if="classifyList.length > 0">
        <el-tab-pane v-for="classifyItem in classifyList" :key="classifyItem.id" :name="String(classifyItem.id)">
          <el-dropdown type="primary" slot="label" size="mini" placement="bottom-end" trigger="click" split-button @command="handleClassify">
            <span class="el-dropdown-link">
              {{classifyItem.title}}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{name: 'edit', id: classifyItem.id}">编辑</el-dropdown-item>
              <el-dropdown-item :command="{name: 'delete', id: classifyItem.id}">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="img-list" v-if="photoList.length !== 0">
            <div class="img-item" v-for="item in photoList" :index="item.praise" :key="item.id">
              <el-image :key="item.id" :src="item.url" lazy></el-image>
              <div class="photo-info">
                <el-button type="danger" size="mini" @click="delPhotoItem(item.id)">删除</el-button>
              </div>
              <div class="praise">
                <span>点赞数</span>
                <p>{{item.praise}}</p>
              </div>
            </div>
          </div>
          <el-empty :image-size="200" v-else></el-empty>
        </el-tab-pane>
      </el-tabs>
      <div class="no-classify" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
      <div class="add-new-classify">
        <el-button type="warning" size="mini" @click="addClassifyVisible = true">添加分类</el-button>
      </div>
      <div class="upload-new" v-if="classifyList.length > 0">
        <el-button type="success" size="mini" @click="addPhotosVisible = true">上传图片</el-button>
        <el-button type="danger" size="mini" @click="toAddUser" v-if="upload_type === 1">添加上传人员</el-button>
      </div>
    </div>
    <!-- 添加图片分类弹窗 -->
    <el-dialog title="添加分类" :visible.sync="addClassifyVisible" width="450px" :before-close="addClassifyClose">
      <div class="dialog-form">
        <el-form :model="addClassifyForm" :rules="addClassifyRules" ref="addClassifyRef" class="dialog-form-content" label-position="top">
          <el-form-item label="排序" prop="sort">
            <el-input-number size="medium" v-model="addClassifyForm.sort" :min="0" :step-strictly="true"></el-input-number>
          </el-form-item>
          <el-form-item label="分类名称" prop="title">
            <el-input v-model="addClassifyForm.title"></el-input>
          </el-form-item>
          <el-form-item label="上传权限" prop="upload_type">
            <el-radio-group v-model="addClassifyForm.upload_type">
              <el-radio :label="1">仅限指定人员</el-radio>
              <el-radio :label="2">所有人均可上传</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="每人可上传数量" prop="upload_num">
            <el-input-number size="medium" v-model="addClassifyForm.upload_num" :min="0" :step-strictly="true"></el-input-number>
            <el-checkbox v-model="addClassifyForm.upload_num" :true-label="0" :false-label="1" style="margin-left: 12px">无限制</el-checkbox>
          </el-form-item>
          <el-form-item label="是否需要权限" prop="is_auth">
            <el-radio-group v-model="addClassifyForm.is_auth">
              <el-radio :label="1">需要</el-radio>
              <el-radio :label="2">不需要</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassifyVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClassifySubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑图片分类弹窗 -->
    <el-dialog title="编辑分类" :visible.sync="editClassifyVisible" width="450px" :before-close="editClassifyClose">
      <div class="dialog-form">
        <el-form :model="editClassifyForm" :rules="editClassifyRules" ref="editClassifyRef" class="dialog-form-content" label-position="top">
          <el-form-item label="排序" prop="sort">
            <el-input-number size="medium" v-model="editClassifyForm.sort" :min="0" :step-strictly="true"></el-input-number>
          </el-form-item>
          <el-form-item label="分类名称" prop="title">
            <el-input v-model="editClassifyForm.title"></el-input>
          </el-form-item>
          <el-form-item label="上传权限" prop="upload_type">
            <el-radio-group v-model="editClassifyForm.upload_type">
              <el-radio :label="1">仅限指定人员</el-radio>
              <el-radio :label="2">所有人均可上传</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="每人可上传数量" prop="upload_num">
            <el-input-number size="medium" v-model="editClassifyForm.upload_num" :min="0" :step-strictly="true"></el-input-number>
            <el-checkbox v-model="editClassifyForm.upload_num" :true-label="0" :false-label="1" style="margin-left: 12px">无限制</el-checkbox>
          </el-form-item>
          <el-form-item label="是否需要权限" prop="is_auth">
            <el-radio-group v-model="editClassifyForm.is_auth">
              <el-radio :label="1">需要</el-radio>
              <el-radio :label="2">不需要</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClassifyVisible = false">取 消</el-button>
        <el-button type="primary" @click="editClassifySubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传图片弹窗 -->
    <el-dialog title="上传图片" :visible.sync="addPhotosVisible" width="60%" :before-close="addPhotosClose">
      <div class="dialog-form">
        <el-form :model="uploadPhotosForm" ref="addPhotosRef" class="dialog-form-content" label-position="top">
          <el-form-item label="选取图片（多张）" prop="uploadList">
            <upload :urlArr="uploadPhotosForm.uploadList" :filed="'uploadList'" :max-num="10" @checked-files="checkedFiles"></upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPhotosVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPhotosSubmit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加上传人员弹窗 -->
    <el-dialog title="添加上传人员" :visible.sync="addUsersVisible" width="60%" :before-close="addUserClose">
      <el-alert
        title="特别提醒"
        type="success"
        description="被选择的用户可在小程序端所有分类下均可上传图片,被选取用户在小程序端至少需要进入一次本会议" style="text-align: left">
      </el-alert>
      <div class="dialog-form">
        <el-table ref="userTable" :data="userList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
          <el-table-column prop="openid" label="Openid"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="nick" label="昵称"></el-table-column>
          <el-table-column label="头像" width="120">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.avatar"></el-avatar>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUsersVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
export default {
  name: 'Index',
  components: {
    Upload
  },
  data () {
    return {
      // 当前激活的照片分类ID
      activeClassify: String(0),
      // 图片分类列表
      classifyList: [],
      // 图片列表
      photoList: [],
      // 当前图片列表是否需要指定上传人员
      upload_type: 1,
      // 待上传图片列表
      uploadPhotosForm: {
        uploadList: []
      },
      addClassifyVisible: false,
      addClassifyForm: {
        mId: this.$route.params.mId,
        sort: 0,
        title: '',
        upload_type: 1,
        upload_num: 1,
        is_auth: 2
      },
      addClassifyRules: {
        title: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      },
      editClassifyVisible: false,
      editClassifyForm: {
        id: 0,
        sort: 0,
        title: '',
        upload_type: 1,
        upload_num: 1,
        is_auth: 2
      },
      editClassifyRules: {
        title: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ]
      },
      // 上传图片弹窗是否可见
      addPhotosVisible: false,
      // 添加可上传人员弹窗
      addUsersVisible: false,
      // 用户列表
      userList: [],
      // 已选择的用户
      userSelection: []
    }
  },
  created () {
    // 获取图片分类列表
    this.getClassifyList()
  },
  methods: {
    // 获取图片分类列表
    async getClassifyList () {
      const { data: res } = await this.$http.get('/shell-photos-classify-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        this.classifyList = res.data
        if (res.data.length > 0) {
          this.activeClassify = String(res.data[0].id)
          // 首次进入页面时获取图片列表
          await this.getPhotoList(res.data[0].id)
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取图片列表
    async getPhotoList (id) {
      const chekcedId = id || this.activeClassify
      const { data: res } = await this.$http.get('/shell-photos-list', { params: { id: chekcedId } })
      if (res.status === 200) {
        this.photoList = res.data.photos
        this.upload_type = res.data.upload_type
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-photos/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭添加分类弹出窗
    addClassifyClose () {
      this.addClassifyVisible = false
    },
    // 添加分类提交按钮
    addClassifySubmit () {
      this.$refs.addClassifyRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/shell-photos-classify/0', this.addClassifyForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            await this.getClassifyList()
            this.addClassifyVisible = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭编辑分类弹出窗
    editClassifyClose () {
      this.editClassifyVisible = false
    },
    // 编辑分类提交按钮
    async editClassifySubmit () {
      const { data: res } = await this.$http.put('/shell-photos-classify/' + this.editClassifyForm.id, this.editClassifyForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.editClassifyVisible = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 切换分类触发
    changeClassify (item) {
      this.activeClassify = String(item.name)
      this.getPhotoList(item.name)
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.uploadPhotosForm.uploadList = data.url
    },
    // 确认上传图片
    async addPhotosSubmit () {
      const { data: res } = await this.$http.post('/shell-photos/0', { mId: this.$route.params.mId, classify: Number(this.activeClassify), urlArr: this.uploadPhotosForm.uploadList })
      if (res.status === 200) {
        this.$message.success(res.msg)
        // 清空待上传表单
        this.uploadPhotosForm.uploadList = []
        // 重新获取图片列表
        await this.getPhotoList(Number(this.activeClassify))
        this.activeClassify = String(this.activeClassify)
        this.addPhotosVisible = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭上传图片弹窗
    addPhotosClose () {
      this.uploadPhotosForm.uploadList = []
      this.addPhotosVisible = false
    },
    // 删除某一张图片
    async delPhotoItem (id) {
      const { data: res } = await this.$http.delete('/shell-photos/' + id)
      if (res.status === 200) {
        await this.getPhotoList(this.activeClassify)
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加上传人员
    async toAddUser () {
      const { data: res } = await this.$http.get('/shell-photo-user-list', { params: { m_id: this.$route.params.mId, tid: this.activeClassify } })
      if (res.status === 200) {
        this.userList = res.data.userList
        this.$nextTick(() => {
          this.userList.forEach(row => {
            res.data.userSelection.forEach(row2 => {
              if (row2.m_id === row.m_id) {
                this.$refs.userTable.toggleRowSelection(row)
              }
            })
          })
        })
        this.addUsersVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭选择用户弹出窗
    addUserClose () {
      this.addUsersVisible = false
    },
    handleSelectionChange (val) {
      this.userSelection = val
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.userTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.userTable.clearSelection()
      }
    },
    // 提交用户
    async addUserSubmit () {
      const { data: res } = await this.$http.post('/shell-photo-user', { userSelection: this.userSelection, tid: this.activeClassify })
      if (res.status === 200) {
        this.addUsersVisible = false
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async handleClassify (command) {
      // 删除分类
      if (command.name === 'delete') {
        const { data: res } = await this.$http.delete('/shell-photos-classify/' + command.id)
        if (res.status === 200) {
          this.$message.success(res.msg)
          await this.getClassifyList()
        } else {
          this.$message.error(res.msg)
        }
      } else if (command.name === 'edit') {
        const { data: res } = await this.$http.get('/shell-photos-classify/' + command.id)
        if (res.status === 200) {
          this.editClassifyForm.id = res.data.id
          this.editClassifyForm.sort = res.data.sort
          this.editClassifyForm.title = res.data.title
          this.editClassifyForm.upload_type = Number(res.data.upload_type)
          this.editClassifyForm.upload_num = Number(res.data.upload_num)
          this.editClassifyForm.is_auth = Number(res.data.is_auth)
          this.editClassifyVisible = true
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  },
  mounted () {
    console.log(this.$refs.waterfall)
  }
}
</script>

<style scoped>
.page-content{
  height: calc(100vh - 330px);
  position: relative;
}
.page-content .el-dropdown{
  width: 100%;
  text-align: left;
}
.page-content .el-dropdown .el-dropdown-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.is-active .el-dropdown /deep/.el-button{
  color: #FFFFFF;
  background-color: #409EFF;
  border-color: #409EFF;
}
.el-dropdown /deep/.el-button{
  background-color: rgba(0,0,0,0);
  color: #409EFF;
  border: 0;
}
.page-content .add-new-classify{
  position: absolute;
  left: 20px;
  bottom: 20px;
}
.page-content .upload-new{
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dialog-form{
  padding: 20px;
}
/deep/.el-tabs__content{
  overflow-y: auto;
  height: 100%;
}
/deep/.waterfall__item{
  background-color: rgba(255,255,255,1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.page-content .img-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.img-item{
  width: 18%;
  margin: 1%;
  background-color: #d3dce6;
  padding: 10px;
  border-radius: 4px;
  position: relative;
}
.img-item img{
  width: 100%;
  height: 100%;
}
.img-item .praise{
  position: absolute;
  top: 14px;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DCDFE6;
  padding: 4px;
  border-radius: 4px;
}
.img-item .praise span{
  color: #606266;
  font-size: 13px;
}
.img-item .praise p{
  margin: 0 0 0 4px;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  color: #F56C6C;
}
</style>
